import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import './SidePanelContent.css'

function SidePanelContent({ progressData }) {
    const getProgressBarVariant = (name) => {
        switch (name) {
          case "ก่อนเรียน":
            return "#104C5E"; // Dark teal
          case "เนื้อหา":
            return "#286EFA"; // Blue
          case "หลังเรียน":
            return "#0099B1"; // Cyan
          case "การบ้าน":
            return "#30BA6B"; // Green
          default:
            return "#007bff"; // Default to Bootstrap's primary color
        }
      };

  return (
    <div className="learner-side-panel-content">
      <div className="learner-progress-cards-container d-flex flex-wrap">
        {progressData.map((item, index) => (
          <div key={index} className="learner-progress-card">
            <h5 className="learner-progress-card-title">{item.title}</h5>
            <div className="learner-progress-bars">
              {item.subsections.map((sub, idx) => (
                <div key={idx} className="learner-subsection-progress">
                  <p>{sub.name} <span style={{float:'right'}}>{sub.done} / {sub.total}</span></p>
                  <div className="progress" style={{ backgroundColor: "#EFF0F4" }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${sub.progress}%`,
                        backgroundColor: getProgressBarVariant(sub.name),
                      }}
                      aria-valuenow={sub.progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {`${sub.progress}%`}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SidePanelContent;
