const helpers = {
    formatRewardText: (row) => {
        if (row.is_limit) {
          const redeemed = row.reward_redeems?.length ?? 0;
          const total = row.rew_amount ?? "";
          return `${redeemed} / ${total}`;
        } 
        return "ไม่จำกัด"; // "Unlimited" in Thai
    },
    getMethodText: function (val) {
        return (val === "edit" && "แก้ไข") ||
            (val === "view" && "ช้อมูล") ||
            (val === "create" && "สร้าง") ||
            "จัดการ";
    },
    getClassStatus: function (val) {
        return (val === true && "เริ่มสอนแล้ว") ||
            (val === false && "ยังไม่เริ่มสอน") ||
            "สิ่นสุดการสอน";
    },
    getClassStatusColor: function (val) {
        return (val === true && "#28A745") ||
            (val === false && "#0069FF") ||
            "#F15A29";
    },
    getRoleName: function (val) {
        return (val === "superadmin" && "ผู้ดูแลระบบ") ||
            (val === "admin" && "ผู้ดูแลระบบ") ||
            (val === "teacher" && "ผู้สอน") ||
            (val === "learner" && "ผู้เรียน") ||
            val;
    },
    getStatus: (val) => {
        return (
            (val === true && "ใช้งาน") ||
            (val === false && "ไม่ใช้งาน") ||
            (val === 1 && "ใช้งาน") ||
            (val === 0 && "ไม่ใช้งาน") ||
            (val === "1" && "ใช้งาน") ||
            (val === "0" && "ไม่ใช้งาน") ||
            val
        );
    },
    getLessonGroup: (val) => {
        return (
            (val === "pretest" && "ทดสอบก่อนเรียน") ||
            (val === "posttest" && "ทดสอบหลังเรียน") ||
            (val === "learn" && "เนื้อหา") ||
            (val === "homework" && "การบ้าน") ||
            "เนื้อหา"
        );
    },
    getLessonType: (val) => {
        return (
            (val === "vr-info" && "VR Information") ||
            (val === "vr-interaction" && "VR Interaction") ||
            (val === "content" && "Content") ||
            (val === "question" && "Question") ||
            val
        );
    },
    getLearnStatus: (val) => {
        return (
            (val === "studying" && "กำลังเรียน") ||
            (val === "done" && "เรียนเสร็จสิ้น") ||
            val
        );
    },
    getStatusColor: (val) => {
        return (
            (val === "studying" && "#87BD66") ||
            (val === "done" && "#0069FF") ||
            (val === true && "#87BD66") ||
            (val === false && "#D95350") ||
            val
        );
    },
    getImportColor: (val) => {
        return (
            (val === "ลงทะเบียนใบอนุญาตสำเร็จ" && "#28A745") ||
            (val === "ต่อใบอนุญาตสำเร็จ" && "#0069FF") ||
            (val === "ผู้ใช้งานมีใบอนุญาตนี้แล้ว" && "#0069FF") ||
            "#e06666"
        );
    },
    formatDateGMTH: function (dateStr) {
        const utcDate = new Date(dateStr);
        const modifiedDate = new Date(utcDate.getTime() + 0 * 3600000); // Subtracting 7 hours
    
        const twoDigitFormat = (num) => num.toString().padStart(2, '0');
    
        const day = twoDigitFormat(modifiedDate.getDate());
        const month = twoDigitFormat(modifiedDate.getMonth() + 1); // Months start at 0
        const year = modifiedDate.getFullYear();
        const hours = twoDigitFormat(modifiedDate.getHours());
        const minutes = twoDigitFormat(modifiedDate.getMinutes());
    
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    },
    formatDateThai: function (dateStr) {
        const date = new Date(dateStr);
        
        const thaiMonths = [
            "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", 
            "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
        ];
        
        const day = date.getDate();
        const month = thaiMonths[date.getMonth()]; // Get month in Thai format
        const year = date.getFullYear() + 543; // Convert to Buddhist calendar year
        
        return `${day} ${month} ${year}`;
    },
    getTextColor: function (dateStr) {
        const now = new Date();
        const licDate = new Date(dateStr);
        
        const diffInMonths =
            (licDate.getFullYear() - now.getFullYear()) * 12 +
            (licDate.getMonth() - now.getMonth());
    
        if (diffInMonths < 1) {
            return "red"; // Less than 1 month
        } else if (diffInMonths >= 2 && diffInMonths <= 3) {
            return "green"; // Between 2 and 3 months
        } else {
            return "green"; // More than 3 months
        }
        // return "black"; // More than 3 months
    }
}

export default helpers;