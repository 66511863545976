import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, redirect: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate an error occurred
    return { hasError: true, redirect: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error details (optional)
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  componentDidUpdate() {
    if (this.state.redirect) {
      // Perform redirection to the login page
      window.location.href = "/login";
    }
  }

  render() {
    if (this.state.hasError) {
      // Optional: Show a fallback UI before redirecting
      return <div>Redirecting to login...</div>;
    }

    // Render children if no error
    return this.props.children;
  }
}

export default ErrorBoundary;
