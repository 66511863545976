import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getInstitutionList } from "../api/license.api";
import { getList } from "../api/license.api";
import ImportExcel from "../components/ExcelRenderer";
import Swal from "sweetalert2";
import { verifyTeacherImport, importTeacherData } from "../api/teacher.api";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import EnumRoles from "../constants/EnumRoles";
import helpers from "../helpers/function.helper";
import { showCreateDialog, showErrorDialog, showSuccessDialog } from "../components/Dialog";
import LoadingComponent from "../components/Loading";

const TeacherImport = ({user}) => {
  const navigate = useNavigate();

  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [failedRow, setFailedRow] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [emptyCell, setEmptyCell] = useState(0);
  const [institutionOptions, setInstitutionOptions] = useState([]);
  const [licenseOptions, setLicenseOptions] = useState([]);

  const [institutionData, setInstitutionData] = useState([]);
  const [licenseData, setLicenseData] = useState([]);
  const [licenseCount, setLicenseCount] = useState(0);
  const [formData, setFormData] = useState({
    institution: "",
    license: "",
  });

  const isFormValid = () => {
    const { institution, license } = formData;
    console.log("importData",importData.length)
    // Ensure institution, count, and dates are filled in
    if (!institution || !license) {
      showErrorDialog('กรุณาเลือกข้อมูลให้ครบถ้วน');
      return false;
    }

    if(importData.length === 0){
      showErrorDialog('ไม่พบข้อมูลสำหรับนำเข้า');
      return false;
    }
  
    // All validations passed
    return true;
  };

  const fetchData = async () => {
    console.log("Fetching data...");
    try {
      const insData = await getInstitutionList({
        page_number: 0,
        data_per_page: 1000,
      });

      if (insData) {
        setInstitutionData(insData);

        const institutionOptions = insData.map((ins) => ({
          label: ins.ins_name,
          value: ins.ins_id,
        }));

        setInstitutionOptions(institutionOptions);

        if(user?.role?.name === EnumRoles.STAFF || user?.role?.name === EnumRoles.TEACHER){
          setFormData((prevData) => ({
            ...prevData,
            institution: insData[0].ins_id,
          }));

          const newLicenseOptions = (insData[0].licenses || []).map(
            (lic) => ({
              label: `${lic.lic_count} ใบ ( ${helpers.formatDateThai(lic.lic_ready)} - ${helpers.formatDateThai(lic.lic_expired)} )`,
              value: lic.lic_id,
            })
          );
  
          setLicenseOptions(newLicenseOptions);

        }
      }
    } catch (error) {
      console.error("Fetch Data Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));

    if (name === "institution") {
      const selectedInstitution = institutionData.find(
        (institution) => institution.ins_id === parseInt(selectedOption.value)
      );

      if (selectedInstitution) {
        const newLicenseOptions = (selectedInstitution.licenses || []).map(
          (lic) => ({
            label: `${lic.lic_count} ใบ ( ${helpers.formatDateThai(lic.lic_ready)} - ${helpers.formatDateThai(lic.lic_expired)} )`,
            value: lic.lic_id,
          })
        );

        setLicenseOptions(newLicenseOptions); // Update the license dropdown options

        // Reset the license field in formData
        setFormData((prevData) => ({
          ...prevData,
          license: "", // Reset license if institution changes
        }));
        setLicenseCount(0); // Reset license count
      } else {
        setLicenseOptions([]); // Clear license options if no institution is selected
        setFormData((prevData) => ({
          ...prevData,
          license: "",
        }));
        setLicenseCount(0);
      }
    }

    if (name === "license") {
      const selectedLicense = licenseOptions.find(
        (lic) => lic.value === selectedOption.value
      );

      if (selectedLicense) {
        setLicenseCount(selectedLicense.label);
      } else {
        setLicenseCount(0);
      }
    }
  };

  const handleImport = async (rows) => {
    // Resetting counters
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    // Preparing to store the parsed rows
    const parsedRows = rows
      .slice(1)
      .filter(
        (item) =>
          item[0] !== undefined &&
          item[1] !== undefined &&
          item[2] !== undefined &&
          item[3] !== undefined
      )
      .map((item) => ({
        code: item[0],
        fullname: item[1],
        email: item[2],
        phone: item[3],
      }));

    // Update the state with parsed rows
    await setImportData(parsedRows);

    // Set the total number of rows imported
    setTotalRow(rows.length - 1);

    // Check for empty cells and prepare a list of their locations
    const emptyCells = [];
    rows.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        if (!cell) {
          // Cell is empty or undefined
          emptyCells.push({ row: rowIndex + 1, column: cellIndex + 1 }); // Add 1 to make it human-readable
        }
      });
    });

    // Set the number of empty cells found
    setEmptyCell(emptyCells.length);

    // Alert the user if any empty cells are found
    if (emptyCells.length > 0) {
      showErrorDialog('กรุณากรอกข้อมูลในไฟล์ให้ครบถ้วน')
    }
    console.log(importData);
  };

  const handleDataDetail = (fileName) => {
    console.log("File name:", fileName);
    // You can handle the file name or other details here.
  };

  const verifyData = async () => {
    try {
      try {
        setVerifySuccess(0);
        setVerifySkip(0);
        setVerifyNew(0);
        setFailedRow([]);
        let create = 0;
        let success = 0;
        let skip = 0;
        let error = 0;
        const batchSize = 10;
        const failedArr = [];

        const dataToVerify = {
          import_data: importData,
        };

        setLoading(true);
        const response = await verifyTeacherImport(dataToVerify);
        setLoading(false);

        if (response) {
          success += response.data.success;
          skip += response.data.update;
          create += response.data.create;
          setTotalRow(response.data.total);
          const failedData = response.data.data;
          failedData.map((item) => {
            failedArr.push(item);
          });

          setVerifySuccess(response.data.total);
          setVerifySkip(skip);
          setVerifyNew(create);
          setFailedRow(failedArr);
          failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);

          if (failedArr.length < 1) {
            showSuccessDialog('ตรวจสอบข้อมูลสำเร็จ')
          }
        } else {
          error++;
          showErrorDialog('กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบ')
          return;
        }
      } catch (error) {
        showErrorDialog('เกิดข้อผิดพลาด')
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด')
    }
  };

  const importDataExcel = async () => {
    if (!isFormValid()) {
      return;
    }
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      setTotalRow(importData.length);


      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return; 
      }

        setLoading(true);

        const dataToImport = {
          import_data: importData,
          institution_id: formData.institution,
          license_id: formData.license,
          user_type: EnumRoles.TEACHER,
        };

        const response = await importTeacherData(dataToImport);
        setLoading(false);

        if (response && response.data.status === "success") {
          showSuccessDialog('บันทึกข้อมูลเข้าสู่ระบบสำเร็จ')
          setIsVerify(false);
          setImportData(response.data.data)
          // navigate("/teacher-user");
        } else {
          showErrorDialog('กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ')
        }

        setVerifySuccess(response?.data?.total || 0);
        setVerifySkip(response?.data?.skip || 0);
    
    } catch (error) {
      console.error("Error importing data:", error);
      showErrorDialog('เกิดข้อผิดพลาดระหว่างการนำเข้าข้อมูล')
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    {isLoading ? (
      <LoadingComponent/>
    ) : (

    <div className="container-fluid">
      <div className="box-white">
        <div className="mt-3">
          <h2>ผู้สอน I เพิ่มผู้สอนและใบอนุญาต</h2>
          <h5 className="px-3 mb-2 text-header">เพิ่มผู้สอนและใบอนุญาต</h5>
          <div className="row justify-content-start m-0 pt-3">
            <Row className="mt-3 px-5">
              <Col xs={"auto"} md={1} className="align-self-center">
                <label className="fontLight font-size03 colorGray01 wh-sp">
                  ชื่อสถาบัน
                </label>
              </Col>
              <Col xs={12} md={4}>
                <Select
                  isDisabled={user?.role?.name !== EnumRoles.ADMIN && user?.role?.name !== EnumRoles.SUPERADMIN}
                  name="institution"
                  value={institutionOptions.find(
                    (option) => option.value === formData.institution
                  )}
                  onChange={handleSelectChange}
                  options={institutionOptions}
                  placeholder="Please select"
                />
              </Col>
            </Row>

            <Row className="mt-3 px-5">
              <Col xs={"auto"} md={1} className="align-self-center">
                <label className="fontLight font-size03 colorGray01 wh-sp">
                  ใบอนุญาต
                </label>
              </Col>
              <Col xs={12} md={4}>
                <Select
                  name="license"
                  value={
                    licenseOptions.find((option) => option.value === formData.license) || ""
                  }
                  onChange={handleSelectChange}
                  options={licenseOptions}
                  placeholder="Please select"
                />
              </Col>
            </Row>

            <Row className="mt-3 px-5">
              <Col Col xs={"auto"} md={1} className="align-self-center">
                <label className="fontLight font-size03 colorGray01 wh-sp">
                  แบบฟอร์ม
                </label>
              </Col>
              <Col xs={12} md={4}>
                <ImportExcel
                  onImport={handleImport}
                  datDetailExcel={handleDataDetail}
                />
                {importData.length > licenseCount ? (
                  <span className="text-danger mb-1">
                    ใบอนุญาต: ใบอนุญาตคงเหลือ {licenseCount} ข้อมูลนำเข้า{" "}
                    {importData.length}
                  </span>
                ) : (
                  <></>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={"auto"}>
                <button
                  className="btn-cancel m-1 mt-5"
                  onClick={() => navigate("/teacher-user")}
                >
                  <span className="mx-1">ยกเลิก</span>
                </button>
                <button
                  disabled={
                    formData.institution === "" ||
                    importData.length > licenseCount
                  }
                  className="btn-save m-1 mt-5"
                  onClick={() => importDataExcel()}
                >
                  <span className="mx-1">สร้าง</span>
                </button>
              </Col>
            </Row>
          </div>
          <div
            className={`card-white mt-3  ${
              importData.length > 0 ? "d-block" : "d-none"
            } `}
          >
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              หมายเหตุ: กรุณาตรวจสอบข้อมูลก่อนนำข้อมูลเข้าสู่ระบบ
            </p>
            {importData.length ? (
              <div className="row">
                <table className="student-scores">
                  <thead>
                    <tr>
                      <th>รหัส</th>
                      <th>ชื่อ</th>
                      <th>อีเมล</th>
                      <th>เบอร์โทรศัพท์</th>
                      <th>หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {importData.map((item,index) => (
                      <tr key={index+1}>
                        <td>{item?.code}</td>
                        <td>{item?.fullname}</td>
                        <td>{item?.email}</td>
                        <td>{item?.phone}</td>
                        <td style={{color: helpers.getImportColor(item?.remark)}}>{item?.remark}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
          )}
      </>
  );
};

export default TeacherImport;
