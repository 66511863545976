import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';
import './Sidebar.css';
import sidebarLogo from '../assets/sidebar_logo.png';
import EnumRoles from '../constants/EnumRoles';
import dashboardIcon from '../assets/icons/ic_menu_dashboard.png'
import logoutmenuIcon from '../assets/icons/ic_menu_logout.png'
import classroomIcon from '../assets/icons/ic_classroom.png'
import testingIcon from '../assets/icons/ic_question.png'
import rewardIcon from '../assets/icons/ic_reward.png'
import modelvrIcon from '../assets/icons/ic_menu_modelvr.png'
import certificateIcon from '../assets/icons/ic_menu_certificate.png'
import teacherIcon from '../assets/icons/ic_menu_teacher.png'
import learnerIcon from '../assets/icons/ic_menu_learner.png'
import insIcon from '../assets/icons/ic_menu_insitution.png'
import licIcon from '../assets/icons/ic_menu_licence.png'
import icSub from '../assets/icons/ic_submenu.png'
import icLine from '../assets/icons/ic_contact_line.png'
import icMail from '../assets/icons/ic_contact_mail.png'
import { showLogoutDialog, showSuccessDialog } from './Dialog';

const Sidebar = ({ collapsed }) => {
  const [isRewardMenuOpen, setIsRewardMenuOpen] = useState(false); // State to manage submenu open/close
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUserContext();

  const handleLogout = () => {
    showLogoutDialog().then((result) => {
      if (result.isConfirmed) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          navigate('/login');
          showSuccessDialog('ออกจากระบบสำเร็จ')
      }
    });
  };

  const role = user?.role?.name;
  const ins_id = user?.ins_id
  let menuItems = [];

  if(role === EnumRoles.ADMIN || role === EnumRoles.SUPERADMIN){
    menuItems = [
      { path: '/', icon: dashboardIcon, label: 'แดชบอร์ด', code: 'dashboard' },
      { path: '/institutions', icon: insIcon, label: 'สถาบัน', code: 'institution_manage' },
      { path: '/licenses', icon: licIcon, label: 'ใบอนุญาต', code: 'licence_manage' },
      { path: '/teacher-user', icon: teacherIcon, label: 'ผู้สอน', code: 'teacher_manage' },
      { path: '/learner-user', icon: learnerIcon, label: 'จัดการผู้เรียน', code: 'learner_manage' },
      { path: '/modelvr', icon: modelvrIcon, label: 'แบบจำลองเสมือนจริง', code: 'modelvr_manage' },
      { path: '/certificate', icon: certificateIcon, label: 'ใบรับรอง', code: 'certificate_manage' },
    ];
  } else if (role === EnumRoles.STAFF){
    menuItems = [
      { path: '/', icon: dashboardIcon, label: 'แดชบอร์ด', code: 'dashboard' },
      { path: `/institutions/action/${ins_id}?method=edit`, icon: insIcon, label: 'สถาบัน', code: 'institution_manage' },
      { path: '/licenses', icon: licIcon, label: 'ใบอนุญาต', code: 'licence_manage' },
      // { path: '/teacher-user', icon: teacherIcon, label: 'ผู้สอน', code: 'teacher_manage' },
      // { path: '/learner-user', icon: learnerIcon, label: 'จัดการผู้เรียน', code: 'learner_manage' },
    ];
  } else if (role === EnumRoles.TEACHER) {
    menuItems = [
      { path: '/student-dashboard', icon: dashboardIcon, label: 'อันดับคะเเนนนักเรียน', code: 'student_dashboard' },
      { path: '/classmanage', icon: classroomIcon, label: 'ห้องสอนของฉัน', code: 'teacher_class_manage' },
      { path: '/test-manage', icon: testingIcon, label: 'แบบทดสอบ', code: 'test_manage' },
      {
        label: 'ของรางวัล',
        icon: rewardIcon,
        code: 'reward_manage',
        isSubMenu: true,
        items: [
          { path: '/reward-category', label: 'หมวดหมู่', code: 'reward_category' },
          { path: '/rewardmanage', label: 'รายการ', code: 'reward_manage' },
          { path: '/reward-redeem', label: 'ประวัติแลกคะแนน', code: 'reward_history' },
        ],
      },
      { path: '/modelvr', icon: modelvrIcon, label: 'แบบจำลองเสมือนจริง', code: 'modelvr_manage' },
      { path: '/certificate', icon: certificateIcon, label: 'ใบรับรอง', code: 'certificate_manage' },
      // { path: '/teacher-user', icon: teacherIcon, label: 'ผู้สอน', code: 'teacher_manage' },
      // { path: '/learner-user', icon: learnerIcon, label: 'จัดการผู้เรียน', code: 'learner_manage' },
    ];
  } else if (role === EnumRoles.LEARNER) {
    menuItems = [
      { path: '/myclass', icon: classroomIcon, label: 'ห้องเรียนของฉัน', code: 'learner_class' },
      { path: '/reward-history', icon: rewardIcon, label: 'ประวัติแลกคะแนน', code: 'reward_history' },
    ];
  }

  const getNavItemClass = (itemPath) => {
    const pathname = location.pathname + location.search; // Include query parameters in the path check
    if (itemPath === '/') {
      return location.pathname === '/' ? 'active' : '';
    }
    return pathname.startsWith(itemPath) ? 'active' : '';
  };

  const handleSubMenuToggle = () => {
    setIsRewardMenuOpen(!isRewardMenuOpen);
  };

  return (
    <aside className={`main-sidebar ${collapsed ? 'collapsed' : ''} fontReg`}>
      <a href="#" className="brand-link">
        <img src={sidebarLogo} alt="SkillsUp" className="brand-image" />
      </a>
      <div className="sidebar-title">
        เมนู
      </div>
      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column fw-bold" data-widget="treeview" role="menu" data-accordion="false">
            {menuItems.map((item) => {
              if (item.isSubMenu) {
                return (
                  <li key={item.label} className={`nav-item  ${isRewardMenuOpen ? 'menu-open' : ''}`}>
                    <a href="#" className="nav-link" onClick={handleSubMenuToggle}>
                      <img src={item.icon} alt={item.label} width={'25px'} height={'auto'} />
                      <span className="nav-text ml-2">{item.label}</span>
                      <i className={`sidebar-arrow ml-auto right fas fa-angle-left ${isRewardMenuOpen ? 'rotate-90' : ''}`} />
                    </a>
                    <ul className={`mt-2 nav nav-treeview ${isRewardMenuOpen ? 'menu-open' : 'd-none'} `}>
                      {item.items.map((subItem) => (
                        user?.role?.menus?.some(menu => menu.code === subItem.code) && (
                          <li key={subItem.path} className={`nav-item ${getNavItemClass(subItem.path)}`}>
                            <Link to={subItem.path} className="nav-link ml-1">
                            <img src={icSub} alt={item.label} width={'15px'} height={'auto'} />

                              <span className="nav-text ml-4">{subItem.label}</span>
                            </Link>
                          </li>
                        )
                      ))}
                    </ul>
                  </li>
                );
              } else {
                return (
                  user?.role?.menus?.some(menu => menu.code === item.code) && (
                    <li key={item.path} className={`nav-item ${getNavItemClass(item.path)}`}>
                      <Link to={item.path} className="nav-link">
                        <img src={item.icon} alt={item.path} width={'25px'} height={'auto'} />
                        <span className="nav-text ml-2">{item.label}</span>
                      </Link>
                    </li>
                  )
                );
              }
            })}
            <li key='logout' className="nav-item">
              <button className="nav-link" onClick={handleLogout} style={{ width: '100%' }}>
                <img src={logoutmenuIcon} alt={'sidebar-logout'} width={'25px'} height={'auto'} />
                <span className="nav-text ml-2">ออกจากระบบ</span>
              </button>
            </li>

          {/* Contact Section */}
          <div className={`contact-section ${collapsed ? 'collapsed' : ''}`}>
          <p className="contact-title">ติดต่อสอบถาม</p>
            <a href="https://page.line.me/442rwopf" className="contact-link d-flex align-items-center mb-2">
              <img src={icLine} alt="Line OA" width="20px" className="mr-2" />
              <span className="contact-text">Line OA</span>
            </a>
            <a href="mailto:itsup.dataassist@gmail.com" className="contact-link d-flex align-items-center">
              <img src={icMail} alt="Mail Support" width="20px" className="mr-2" />
              <span className="contact-text">Mail Support</span>
            </a>
          </div>

          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
